import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import Config from '@config/config';
import { CalculateBalancingScoreGTSig, FetchArticlesTableGTSig, FetchOverallScoreGTSig, FetchTopWorstArticlesGTSig, FetchTopWorstAuthorsGTSig, FetchUniqueLanguagesGTSig } from '@store/gender-tracker/genderTracker.thunk';
import { getStoreState } from '@store/storeConfig';

import { BaseClientAuthConfig } from './_baseClient';

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;

class CGBTAPIClient {
    protected _session: AxiosInstance;
    protected _config: BaseClientAuthConfig = {};

    private constructor(enforcer) {
        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._config  ={};
            this._session = axios.create({ baseURL: `${api.gbt}/api/` });
        } catch (e: unknown) {
            console.error(e);
        }
    }

    static get instance(): CGBTAPIClient & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new CGBTAPIClient(singletonEnforcer);
        }

        return this[singleton];
    }

    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.get<T>(url, config);
    }

    post<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.post<T>(url, data, config);
    }

    getClientWebsite() {
        const state         = getStoreState();
        const clientWebsite = state.authenticationReducer?.user?.site?.website;

        return clientWebsite;
    }

    muteLinks<T>(
        link: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.get<T>('articles/mute-links/', {
            ...this._config,
            ...config,
            params: {
                link
            }
        });
    }

    calculateGenderBalancingScore<T>(
        { text, author }: CalculateBalancingScoreGTSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.get<T>('texts/calculate_gender_balancing_score/', {
            ...this._config,
            ...config,
            params: {
                text,
                author
            }
        });
    }

    getSingleArticle<T>(
        link: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.get<T>('articles/article/', {
            ...this._config,
            ...config,
            params: {
                link
            }
        });
    }

    getTopWorstArticles<AxiosReturn>(
        { from, to,  }: FetchTopWorstArticlesGTSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('articles/best-and-worst-articles/', {
            ...this._config,
            ...config,
            params: {
                startDate: from,
                endDate: to,
                domainInput: website,
            }
        });
    }

    getTopWorstAuthors<AxiosReturn>(
        { from, to }: FetchTopWorstAuthorsGTSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('authors/best-and-worst-authors/', {
            ...this._config,
            ...config,
            params: {
                startDate: from,
                endDate: to,
                domainInput: website,
            }
        });
    }

    getOverallScore<AxiosReturn>({
        from, to, compareFrom, compareTo
    }: FetchOverallScoreGTSig, config?: AxiosRequestConfig): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('scores/average-and-daily-scores/', {
            ...this._config,
            ...config,
            params: {
                startDate: from,
                endDate: to,
                pastStartDate: compareFrom,
                pastEndDate: compareTo,
                // past_start_date: compareFrom,
                // past_end_date: compareTo,
                domainToMention: website,
            }
        });
    }

    getUniqueLanguages<AxiosReturn>({
        from, to
    }: FetchUniqueLanguagesGTSig, config?: AxiosRequestConfig): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('articles/unique-languages/', {
            ...this._config,
            ...config,
            params: {
                startDate: from,
                endDate: to,
                domainInput: website,
            }
        });
    }

    getArticlesTable<AxiosReturn>(
        { from, to, limit, offset, order, sort, search, Language }: FetchArticlesTableGTSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('articles/articles-by-date-range/', {
            ...this._config,
            ...config,
            params: {
                startDate: from,
                endDate: to,
                limit,
                offset,
                search,
                sort,
                order,
                Language,
                domainInput: website,
            }
        });
    }
}

export const GBTAPIClient = CGBTAPIClient.instance;
