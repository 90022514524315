import { useTheme } from '@emotion/react';
import { Body, Cell, Header, HeaderCell, HeaderRow, Row, Table } from '@table-library/react-table-library';
import { getTheme } from '@table-library/react-table-library/baseline';
import { useEffect, useState } from 'react';

import { InputTarget } from '@components/common';
import { ComponentInfo } from '@components/component-info';
import { LoaderWrapper, SavingLoader } from '@components/widgets';
import { neitherNullNorUndefined } from '@services/validation/validation';

import { useAppDispatch, useAppSelector } from '@hooks/index';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { DataStatus } from '@shared/enum/dataStatus';
import { selectedFromImpactScore } from '@store/impact-score/impactScore.slice';
import { getImpactScoreMetrics, updateImpactScoreMetrics } from '@store/impact-score/impactScore.thunk';
import classNames from 'classnames';
import './ImpactScoreSettings.scss';
import ISSettingsCoefWrapper from './coef-wrapper/ISSettingsCoefWrapper';

interface NodeItem {
    id: string;
    title: string;
    subTitle?: string;
    metric?: string;
    tooltip?: string;
    tooltipPosition?: {
        left?: string;
        right?: string;
        top?: string;
        bottom?: string;
    };
    coef: number;
    target?: string;
    targetUnit?: string;
    targetSymbol?: string;
}

const overall = [
    {
        id: 'total-page-views',
        metric: 'views',
        title: 'Total page views',
        tooltip: '',
        coef: 5,
        target: '',
        targetUnit: '/month',
        targetSymbol: '',
    },
    {
        id: 'total-page-views-compared',
        metric: 'pourcentageCurrentVisitsVsPastVisits',
        title: 'Total page views vs previous period',
        tooltip: 'tooltip here',
        coef: 4,
        target: '',
        targetUnit: '%',
        targetSymbol: '',
    },
    {
        id: 'avg-time-on-site',
        metric: 'onSiteDuration',
        title: 'Avg time on site',
        tooltip: '',
        coef: 1,
        target: '',
        targetUnit: 'sec',
        targetSymbol: '>',
    },
    {
        id: 'depth-rate',
        metric: 'depthRate',
        title: 'Depth rate',
        tooltip: 'tooltip here',
        coef: 3,
        target: '',
        targetUnit: '',
        targetSymbol: '>',
    },
    {
        id: 'bounce-rate',
        metric: 'bounceRate',
        title: 'Bounce rate',
        tooltip: 'tooltip here',
        coef: 2,
        target: '',
        targetUnit: '%',
        targetSymbol: '<',
    },
    {
        id: 'returning-visitors-vs-visitors',
        metric: 'returningVisitorsVsVisitors',
        title: 'Returning visitors vs visitors',
        tooltip: '',
        coef: 5,
        target: '',
        targetUnit: '% of visitors',
        targetSymbol: '>',
    },
    {
        id: 'organic-search',
        metric: 'pourcentageOrganic',
        title: 'Organic search/acquisition',
        tooltip: '',
        coef: 2,
        target: '',
        targetUnit: '% of visits',
        targetSymbol: '>',
    },
    {
        id: 'referrals-traffic',
        metric: 'referralTraffic',
        title: 'Referrals traffic',
        tooltip: 'tooltip here',
        coef: 4,
        target: '',
        targetUnit: '% of visits',
        targetSymbol: '>',
    },
    {
        id: 'engagement-rate-instagram',
        metric: 'engagementInstagram',
        title: 'Engagement rate instagram',
        tooltip: 'tooltip here',
        coef: 2,
        target: '',
        targetUnit: '%',
        targetSymbol: '>',
    },
    {
        id: 'engagement-rate-facebook',
        metric: 'engagementFacebook',
        title: 'Engagement rate facebook',
        tooltip: 'tooltip here',
        coef: 4,
        target: '',
        targetUnit: '%',
        targetSymbol: '>',
    },
    {
        id: 'engagement-rate-youtube',
        metric: 'engagementYoutube',
        title: 'Engagement rate youtube',
        tooltip: 'tooltip here',
        coef: 2,
        target: '',
        targetUnit: '%',
        targetSymbol: '>',
    },
    {
        id: 'engagement-rate-twitter',
        metric: 'engagementTwitter',
        title: 'Engagement rate Twitter',
        tooltip: 'tooltip here',
        coef: 4,
        target: '',
        targetUnit: '%',
        targetSymbol: '>',
    },
    {
        id: 'engagement-rate-x',
        metric: 'engagementX',
        title: 'Engagement rate X',
        tooltip: 'tooltip here',
        coef: 2,
        target: '',
        targetUnit: '%',
        targetSymbol: '>',
    },
    {
        id: 'conversion-rates',
        metric: 'conversionRate',
        title: 'Conversion rates',
        tooltip: 'tooltip here',
        coef: 4,
        target: '',
        targetUnit: '%',
        targetSymbol: '>',
    },
];

const single = [
    {
        id: 'total-pageviews-articles',
        metric: 'views',
        title: 'Total pageviews',
        subTitle: 'AVG all articles pageviews',
        coef: 5,
    },
    {
        id: 'total-pageviews-categories',
        metric: 'avgPageViewsPerCategory',
        title: 'Total pageviews',
        subTitle: 'AVG all category pageviews',
        coef: 4,
    },
    {
        id: 'average-pageviews',
        metric: 'avgViews',
        title: 'Average pageviews',
        subTitle: 'AVG all category pageviews',
        coef: 4,
    },
    {
        id: 'avg-reading-time-all',
        metric: 'onPageDuration',
        title: 'Avg reading time',
        subTitle: 'Avg all reading time',
        coef: 1,
    },
    {
        id: 'avg-reading-time-category',
        metric: 'avgDurationPerCategory',
        title: 'Avg reading time',
        subTitle: 'Avg category reading time',
        coef: 3,
    },
    {
        id: 'depth-rate',
        metric: 'depthRate',
        title: 'Depth rate',
        coef: 2,
        target: '',
        targetUnit: '%',
    },
    {
        id: 'bounce-rate',
        metric: 'bounceRate',
        title: 'Bounce rates',
        coef: 5,
        target: '',
        targetUnit: '%',
    },
    {
        id: 'returning-visitors',
        metric: 'returningVisitors',
        title: 'Returning visitors',
        coef: 2,
        target: '',
        targetUnit: '%',
    },
    {
        id: 'conversion-rate',
        metric: 'conversionRate',
        title: 'Conversion rates',
        tooltip: 'tooltip here',
        coef: 4,
        target: '',
        targetUnit: '%',
    },
    {
        id: 'organic-search',
        metric: 'pourcentageOrganic',
        title: 'Organic search/acquisition',
        coef: 4,
        target: '',
        targetUnit: '% of visits',
        targetSymbol: '>',
    },
    {
        id: 'backlinks-percentage',
        metric: 'pourcentageBacklinks',
        title: 'Backlinks percentage',
        coef: 4,
        target: '',
        targetUnit: '% of visits',
        targetSymbol: '>',
    },
];

const ImpactScoreSettings = () => {
    const dispatch         = useAppDispatch();
    const { data, status } = useAppSelector(selectedFromImpactScore.settingsResult);

    const [overallNodes, setOverallNodes]       = useState<NodeItem[]>([]);
    const [overallIsSaving, setOverallIsSaving] = useState<{ id: string; status: boolean }>({
        id: null,
        status: false
    });
    const [singleNodes, setSingleNodes]         = useState<NodeItem[]>([]);
    const [singleIsSaving, setSingleIsSaving]   = useState<{ id: string; status: boolean }>({
        id: null,
        status: false
    });

    useEffect(() => {
        const source = generateNewCancelTokenSource();

        dispatch(getImpactScoreMetrics({
            data: null,
            config: {
                cancelToken: source.token,
            }
        }));

        return () => source?.cancel();
    }, []);

    useEffect(() => {
        if (!data?.metrics?.length) {
            return;
        }

        const siteFilter   = data?.metrics?.filter(item => item.type === 'site');
        const singleFilter = data?.metrics?.filter(item => item.type === 'single');

        setOverallNodes(siteFilter?.map((item) => {
            const o = overall.find(i => i.metric === item.name);

            if (!o) {
                return null;
            }

            return {
                id: o?.id ?? '',
                metric: item.name,
                title: o?.title ?? '',
                tooltip: o?.tooltip ?? '',
                coef: item.coef,
                target: String(item.target),
                targetUnit: o?.targetUnit ?? '',
                targetSymbol: o?.targetSymbol ?? '',
            };
        }).filter(Boolean) ?? []);

        setSingleNodes(singleFilter?.map((item) => {
            const o = single.find(i => i.metric === item.name);

            if (!o) {
                return null;
            }

            return {
                id: o?.id ?? '',
                metric: item.name,
                title: o?.title ?? '',
                subTitle: o?.subTitle ?? '',
                tooltip: o?.tooltip ?? '',
                coef: item.coef,
                target: String(item.target),
                targetUnit: o?.targetUnit ?? '',
                targetSymbol: o?.targetSymbol ?? '',
            };
        }).filter(Boolean) ?? []);
    }, [data]);

    // eslint-ignore-next-line
    // @ts-ignore
    const theme = useTheme(getTheme());

    const onSubmit = (items: NodeItem[], type: 'single' | 'site', id: string) => {
        type === 'single'
            ? setSingleIsSaving({ status: true, id })
            : setOverallIsSaving({ status: true, id });


        const metrics = items.map(item => ({
            name: item.metric,
            coef: item.coef,
            target: Number(item.target) ?? 0,
            type,
        }));

        dispatch(updateImpactScoreMetrics({
            data: {
                metrics,
            },
            config: {},
        })).finally(() => {
            type === 'single'
                ? setSingleIsSaving({ status: false, id })
                : setOverallIsSaving({ status: false, id });
        });
    };

    const isDisabled = (currentId: string) => {
        if (
            (singleIsSaving.status && singleIsSaving.id !== currentId) || (
                overallIsSaving.status && overallIsSaving.id !== currentId
            )
        ) {
            return true;
        }

        return false;
    };

    return (
        <section className='impact-score-settings'>
            <h2>Impact score</h2>
            <p>
                The impact score is a custom score that indicates the impact
                of your platform and effectiveness. It is counted as an average of
                specific metrics in order to define the impact of your platform
                regarding your audience.
            </p>

            <div className='is-separator' />

            <p className="is-secondary-p">
                Then you as users must define a <b>coefficient</b> in order to set
                <b> importance</b> to that metric as well as a <b>target</b> you want to
                <b>reach for that specific metric</b>.
            </p>

            <h2 className="is-head">Overall impact score</h2>

            <div style={{ position: 'relative', minHeight: '100px' }}>
                {status === DataStatus.LOADING && <LoaderWrapper />}

                <Table key="overall-score-table" data={{ nodes: overallNodes }} theme={theme}>
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow className='iss-head-raw'>
                                    <HeaderCell className='iss-head-col'>Metrics</HeaderCell>
                                    <HeaderCell className='iss-head-col'>Coef (Weight of the Metric)</HeaderCell>
                                    <HeaderCell className='iss-head-col'>Target</HeaderCell>
                                </HeaderRow>
                            </Header>
                            <Body>
                                {(tableList as typeof overallNodes).map((item) => (
                                    <Row
                                        className={classNames('iss-raw', {
                                            // eslint-disable-next-line @typescript-eslint/naming-convention
                                            'iss-raw-disabled': isDisabled(item.id)
                                        })}
                                        key={item.id}
                                        item={item}
                                    >
                                        <Cell className='iss-col --noHoverEffect'>
                                            <div className="iss-col-title">
                                                <p>{item.title}</p>
                                                {item?.tooltip && <ComponentInfo
                                                    tooltipContent={item.tooltip}
                                                    tooltipPosition={item?.tooltipPosition || {
                                                        top: '15px',
                                                        left: '-10px',
                                                    }}
                                                />}
                                            </div>
                                        </Cell>
                                        <Cell className='iss-col'>
                                            <div className='iss-col-coef'>
                                                <ISSettingsCoefWrapper
                                                    items={[
                                                        { id: '1', value: 1, selected: item.coef === 1 },
                                                        { id: '2', value: 2, selected: item.coef === 2 },
                                                        { id: '3', value: 3, selected: item.coef === 3 },
                                                        { id: '4', value: 4, selected: item.coef === 4 },
                                                        { id: '5', value: 5, selected: item.coef === 5 },
                                                    ]}
                                                    disabled={isDisabled(item.id)}
                                                    onChange={(value) => {
                                                        if (!isDisabled(item.id)) {
                                                            const overall = overallNodes.map((node) => {
                                                                if (node.id === item.id) {
                                                                    return {
                                                                        ...node,
                                                                        coef: value,
                                                                    };
                                                                }

                                                                return node;
                                                            });

                                                            setOverallNodes(overall);
                                                            onSubmit(overall, 'site', item?.id);
                                                        }
                                                    }}
                                                />
                                                <p>{item.targetSymbol}</p>
                                            </div>
                                        </Cell>
                                        <Cell className='iss-col'>
                                            <div className="iss-col-target">
                                                <InputTarget
                                                    targetUnit={item.targetUnit}
                                                    error={null}
                                                    placeholder='Set your target'
                                                    value={item.target}
                                                    disabled={isDisabled(item.id)}
                                                    onChange={(e) => {
                                                        const { value } = e.target;

                                                        setOverallNodes(overallNodes.map((node) => {
                                                            if (node.id === item.id) {
                                                                return {
                                                                    ...node,
                                                                    target: value,
                                                                };
                                                            }

                                                            return node;
                                                        }));
                                                    }}
                                                    onBlur={(e) => {
                                                        const { value }   = e.target;
                                                        const currentNode = overallNodes.find((node) => node.id === item.id) ;

                                                        if (value === currentNode.target && !isDisabled(item.id)) {
                                                            onSubmit(overallNodes, 'site', item?.id);
                                                        }
                                                    }}
                                                />
                                                {overallIsSaving?.id === item.id && overallIsSaving.status && (
                                                    <SavingLoader nbDots={2} />
                                                )}
                                            </div>
                                        </Cell>
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            </div>


            <h2 className="is-head">Single page impact score</h2>
            <p>
                The impact score of a single article is calculated by the metrics of
                a single article compared to the average, highlighting the contribution
                of that article to the general impact score.
            </p>

            <div style={{ position: 'relative', minHeight: '100px' }}>
                {status === DataStatus.LOADING && <LoaderWrapper />}

                <Table key="single-score-table" data={{ nodes: singleNodes }} theme={theme}>
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow className='iss-head-raw'>
                                    <HeaderCell className='iss-head-col'>Metrics</HeaderCell>
                                    <HeaderCell className='iss-head-col'>Coef (Weight of the Metric)</HeaderCell>
                                    <HeaderCell className='iss-head-col'>Target</HeaderCell>
                                </HeaderRow>
                            </Header>
                            <Body>
                                {(tableList as typeof singleNodes).map((item) => (
                                    <Row
                                        className={classNames('iss-raw', {
                                            // eslint-disable-next-line @typescript-eslint/naming-convention
                                            'iss-raw-disabled': isDisabled(item.id)
                                        })}
                                        key={item.id}
                                        item={item}
                                    >
                                        <Cell className='iss-col --noHoverEffect'>
                                            <div className="iss-col-title">
                                                <div>
                                                    <p>{item.title}</p>
                                                    {item?.subTitle && <small>{item.subTitle}</small>}
                                                </div>
                                                {item?.tooltip && <ComponentInfo
                                                    tooltipContent={item.tooltip}
                                                    tooltipPosition={item?.tooltipPosition || {
                                                        top: '15px',
                                                        left: '-10px',
                                                    }}
                                                />}
                                            </div>
                                        </Cell>
                                        <Cell className='iss-col'>
                                            <div className='iss-col-coef'>
                                                <ISSettingsCoefWrapper
                                                    items={[
                                                        { id: '1', value: 1, selected: item.coef === 1 },
                                                        { id: '2', value: 2, selected: item.coef === 2 },
                                                        { id: '3', value: 3, selected: item.coef === 3 },
                                                        { id: '4', value: 4, selected: item.coef === 4 },
                                                        { id: '5', value: 5, selected: item.coef === 5 },
                                                    ]}
                                                    disabled={isDisabled(item.id)}
                                                    onChange={(value) => {
                                                        if (!isDisabled(item.id)) {
                                                            const newNodes = singleNodes.map((node) => {
                                                                if (node.id === item.id) {
                                                                    return {
                                                                        ...node,
                                                                        coef: value,
                                                                    };
                                                                }

                                                                return node;
                                                            });

                                                            setSingleNodes(newNodes);
                                                            onSubmit(newNodes, 'single', item?.id);
                                                        }
                                                    }}
                                                />
                                                <p>{item.targetSymbol}</p>
                                            </div>
                                        </Cell>
                                        <Cell className='iss-col'>
                                            <div className="iss-col-target">
                                                {neitherNullNorUndefined(item?.target) && (
                                                    <InputTarget
                                                        targetUnit={item.targetUnit}
                                                        error={null}
                                                        placeholder='Set your target'
                                                        value={item.target}
                                                        disabled={isDisabled(item.id)}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            const newNodes  = singleNodes.map((node) => {
                                                                if (node.id === item.id) {
                                                                    return {
                                                                        ...node,
                                                                        target: value,
                                                                    };
                                                                }

                                                                return node;
                                                            });

                                                            setSingleNodes(newNodes);
                                                        }}
                                                        onBlur={(e) => {
                                                            const { value }   = e.target;
                                                            const currentNode = singleNodes.find((node) => node.id === item.id) ;

                                                            if (value === currentNode.target && !isDisabled(item.id)) {
                                                                onSubmit(singleNodes, 'single', item?.id);
                                                            }
                                                        }}
                                                    />
                                                )}

                                                {singleIsSaving?.id === item.id && singleIsSaving.status && (
                                                    <SavingLoader nbDots={2} />
                                                )}
                                            </div>
                                        </Cell>
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            </div>
        </section>
    );
};

export default ImpactScoreSettings;
