import classNames from 'classnames';

import Loader, { LoaderProps } from '../Loader';
import './SavingLoader.scss';

type Props = LoaderProps & {
    className?: string;
};

const SavingLoader = ({ className, ...loadProps }: Props) => (
    <div className={classNames('saving-loader', { [className]: !!className })}>
        <p>saving...</p>
        <Loader {...loadProps} />
    </div>
);

export default SavingLoader;
